"use strict";

/**
 * @typedef IModelDeclaration
 * @property {string} modelName The name of the model.
 * @property {string} typeCode The typeCode for the model.
 * @property {string} baseURL The base URL of the editor for the model.
 * @property {string[]} aliases An array of strings containing other names this model is known as.
 * @property {boolean} [supportsCustomId] A boolean indicating whether or not the specified model supports custom IDs.
 * @property {boolean} [selfApprovable] If true, this model can be approved by the proposer.
 */

/**
 * Declares a model, it's name, it's type code and any other names it may be known as.
 * @enum {IModelDeclaration}
 */
const MODEL_DECLARATIONS = {
  ACCEPTANCE_CRITERIA_RANGE: {
    modelName: "AcceptanceCriteriaRange",
    typeCode: "ACR",
    baseURL: null,
    aliases: ["AcceptanceCriteriaRanges", "AcceptanceCriteriaRange"]
  },
  BATCH: {
    modelName: "Batch",
    typeCode: "BAT",
    baseURL: "batches",
    isInProjectOrGlobalContext: true,
    aliases: ["batch", "batches", "coas", "coa"],
  },
  BATCH_UPLOAD: {
    modelName: "Batch Upload",
    typeCode: "BAU",
    baseURL: null,
    aliases: ["batchupload", "batchuploads"],
  },
  BATCH_UPLOAD_ATTRIBUTES: {
    modelName: "Batch Upload Attribute",
    typeCode: "BUA",
    baseURL: null,
    aliases: ["batchuploadattribute", "batchuploadattributes"],
  },
  COMPANY: {
    modelName: "Company",
    typeCode: "CO",
    baseURL: null,
    aliases: ["company", "companies"],
  },
  CONTROL_METHOD: {
    modelName: "Control Method",
    typeCode: "CM",
    baseURL: "controlMethods",
    isInProjectContext: true,
    aliases: ["controlmethod", "controlmethods"],
  },
  CURRICULUM: {
    modelName: "Curriculum",
    typeCode: "CUR",
    baseURL: "training/curricula",
    aliases: ["curriculum", "curriculums", "curricula"],
  },
  DOCUMENT: {
    modelName: "Document",
    typeCode: "DOC",
    baseURL: "documents",
    aliases: ["document", "documents", "doc", "docs"],
    supportsCustomId: true,
    isInProjectOrGlobalContext: true,
  },
  DOCUMENT_CONTENT: {
    modelName: "Document Content",
    baseURL: null,
    aliases: ["documentcontent", "documentcontents"],
    typeCode: "DCON"
  },
  FPA: {
    modelName: "FPA",
    typeCode: "FPA",
    baseURL: "product/qtpp/fpas",
    isInProjectContext: true,
    aliases: ["fpa", "fpas", "finalperformanceattribute", "finalperformanceattributes", "performance attributes", "performance attribute"],
  },
  FPA_TO_GA: {
    modelName: "FPA to GA",
    typeCode: "FPAToGeneralAttributeRisk",
    baseURL: null,
    aliases: ["fpatogeneralattributerisk", "fpatogeneralattributerisks"],
  },
  FQA: {
    modelName: "FQA",
    typeCode: "FQA",
    baseURL: "product/qtpp/fqas",
    isInProjectContext: true,
    aliases: ["fqa", "fqas", "finalqualityattribute", "finalqualityattributes", "quality attributes", "quality attribute"],
  },
  FQA_TO_GA: {
    modelName: "FQA to GA",
    typeCode: "FQAToGeneralAttributeRisk",
    baseURL: null,
    aliases: ["fqatogeneralattributerisk", "fqatogeneralattributerisks"],
  },
  GA: {
    modelName: "General Attribute",
    typeCode: "GA",
    baseURL: "product/qtpp/generalAttributes",
    isInProjectContext: true,
    aliases: ["ga", "gas", "generalattribute", "generalattributes"],
  },
  IMPORT: {
    modelName: "Import",
    typeCode: "IMT",
    baseURL: null,
    aliases: ["import", "imports"],
  },
  IPA: {
    modelName: "IPA",
    typeCode: "IPA",
    baseURL: "processExplorer/ipas",
    isInProjectContext: true,
    aliases: ["ipa", "ipas", "intermediateperformanceattribute", "intermediateperformanceattributes"],
  },
  IPA_TO_FQA: {
    modelName: "IPA to FQA",
    typeCode: "IPATOFQA",
    baseURL: null,
    aliases: ["ipatofqa", "ipatofqas"],
  },
  IPA_TO_IPA: {
    modelName: "IPA to IPA",
    typeCode: "IPATOIPA",
    baseURL: null,
    aliases: ["ipatoipa", "ipatoipas"],
  },
  IPA_TO_IQA: {
    modelName: "IPA to IQA",
    typeCode: "IPATOIQA",
    baseURL: null,
    aliases: ["ipatoiqa", "ipatoiqas"],
  },
  IQA: {
    modelName: "IQA",
    typeCode: "IQA",
    baseURL: "processExplorer/iqas",
    isInProjectContext: true,
    aliases: ["iqa", "iqas", "intermediatequalityattribute", "intermediatequalityattributes"],
  },
  IQA_TO_FQA: {
    modelName: "IQA to FQA",
    typeCode: "IQATOFQA",
    baseURL: null,
    aliases: ["iqatofqa", "iqatofqas"],
  },
  IQA_TO_IPA: {
    modelName: "IQA to IPA",
    typeCode: "IQATOIPA",
    baseURL: null,
    aliases: ["iqatoipa", "iqatoipas"],
  },
  IQA_TO_IQA: {
    modelName: "IQA to IQA",
    typeCode: "IQATOIQA",
    baseURL: null,
    aliases: ["iqatoiqa", "iqatoiqas"],
  },
  ITP: {
    modelName: "ITP",
    typeCode: "ITP",
    baseURL: "training",
    aliases: ["individualtrainingplan", "individualtrainingplans", "itp", "itps"],
    selfApprovable: true,
  },
  MA: {
    modelName: "Material Attribute",
    typeCode: "MA",
    baseURL: "processExplorer/materialAttributes",
    isInProjectContext: true,
    aliases: ["materialattribute", "materialattributes", "ma", "mas"],
  },
  MA_TO_FPA: {
    modelName: "Material Attribute to FPA",
    typeCode: "MATOFPA",
    baseURL: null,
    aliases: ["materialattributetofpa", "materialattributetofpas", "matofpa", "matofpas"],
  },
  MA_TO_FQA: {
    modelName: "Material Attribute to FQA",
    typeCode: "MATOFQA",
    baseURL: null,
    aliases: ["materialattributetofqa", "materialattributetofqas", "matofqa", "matofqas"],
  },
  MA_TO_IPA: {
    modelName: "Material Attribute to IPA",
    typeCode: "MATOIPA",
    baseURL: null,
    aliases: ["materialattributetoipa", "materialattributetoipas", "matoipa", "matoipas"],
  },
  MA_TO_IQA: {
    modelName: "Material Attribute to IQA",
    typeCode: "MATOIQA",
    baseURL: null,
    aliases: ["materialattributetoiqa", "materialattributetoiqas", "matoiqa", "matoiqas"],
  },
  MT: {
    modelName: "Material",
    typeCode: "MT",
    baseURL: "processExplorer/materials",
    isInProjectContext: true,
    aliases: ["mt", "mts", "material", "materials"],
  },
  PA: {
    modelName: "Performance Attribute",
    typeCode: "PA",
    baseURL: "product/qtpp/fpas",
    aliases: ["pa", "pas", "performanceattribute", "performanceattributes"],
  },
  PP: {
    modelName: "Process Parameter",
    typeCode: "PP",
    baseURL: "processExplorer/processParameters",
    isInProjectContext: true,
    aliases: ["processparameter", "processparameters", "pp", "pps"],
  },
  PP_TO_FPA: {
    modelName: "Process Parameter to FPA",
    typeCode: "PPTOFPA",
    baseURL: null,
    aliases: ["processparametertofpa", "processparametertofpas", "pptofpa", "pptofpas"],
  },
  PP_TO_FQA: {
    modelName: "Process Parameter to FQA",
    typeCode: "PPTOFQA",
    baseURL: null,
    aliases: ["processparametertofqa", "processparametertofqas", "pptofqa", "pptofqas"],
  },
  PP_TO_IPA: {
    modelName: "Process Parameter to IPA",
    typeCode: "PPTOIPA",
    baseURL: null,
    aliases: ["processparametertoipa", "processparametertoipas", "pptoipa", "pptoipas"],
  },
  PP_TO_IQA: {
    modelName: "Process Parameter to IQA",
    typeCode: "PPTOIQA",
    baseURL: null,
    aliases: ["processparametertoiqa", "processparametertoiqas", "pptoiqa", "pptoiqas"],
  },
  PR: {
    modelName: "Process",
    typeCode: "PR",
    baseURL: "processExplorer/processes",
    isInProjectContext: true,
    aliases: ["process", "processes", "pr"],
  },
  PRC: {
    modelName: "Process Component",
    typeCode: "PRC",
    baseURL: "processExplorer/processComponents",
    isInProjectContext: true,
    aliases: ["processcomponent", "processcomponents", "prc"],
  },
  PRJ: {
    modelName: "Project",
    typeCode: "PRJ",
    baseURL: "projects",
    isInProjectContext: true,
    aliases: ["project", "projects"],
  },
  RECORD_ORDER: {
    modelName: "Record Order",
    typeCode: "RECO",
    baseURL: null,
    aliases: ["recordOrder", "recordOrders"],
  },
  REPORT_AUTO_SAVED_LAYOUT: {
    modelName: "Report Auto Saved Layout",
    typeCode: "ASL",
    baseURL: null,
    aliases: ["reportautosavedlayout", "reportautosavedlayouts"],
  },
  REPORT_LAYOUT: {
    modelName: "Report Layout",
    typeCode: "RPL",
    baseURL: null,
    aliases: ["reportlayout", "reportlayouts"],
  },
  REQUIREMENT: {
    modelName: "Requirement",
    typeCode: "REQ",
    baseURL: null,
    aliases: ["requirement", "requirements"]
  },
  RMP: {
    modelName: "RMP",
    typeCode: "RMP",
    baseURL: "rmps",
    aliases: ["rmp", "rmps", "riskmanagementplan", "riskmanagementplans"],
  },
  RMP_TO_CAPABILITY_RISK: {
    modelName: "RMP To Capability Risk",
    typeCode: "RMPTOCPR",
    baseURL: null,
    aliases: ["rmptocapabilityrisk", "rmptocapabilityrisks"],
  },
  RMP_TO_CRITICALITY_RISK_SCALE: {
    modelName: "Criticality Scale",
    typeCode: "CSC",
    baseURL: null,
    aliases: ["rmptoprocessriskscale", "rmptoprocessriskscales"],
  },
  RMP_TO_DETECTABILITY_RISK: {
    modelName: "RMP To Detectability Risk",
    typeCode: "RMPTODTR",
    baseURL: null,
    aliases: ["rmptodetectabilityrisk", "rmptodetectabilityrisks"],
  },
  RMP_TO_IMPACT: {
    modelName: "RMP To Impact",
    typeCode: "RMPTOIMP",
    baseURL: null,
    aliases: ["rmptoimpact", "rmptoimpacts"],
  },
  RMP_TO_PROCESS_RISK_SCALE: {
    modelName: "RMP To Process Risk Scale",
    typeCode: "PSR",
    baseURL: null,
    aliases: ["rmptoprocessriskscale", "rmptoprocessriskscales"],
  },
  RMP_TO_RPN_SCALES: {
    modelName: "RMP To RPN Scales",
    typeCode: "RPN",
    baseURL: null,
    aliases: ["rmptorpnscale", "rmptorpnscales"],
  },
  RMP_TO_UNCERTAINTY: {
    modelName: "RMP To Uncertainty",
    typeCode: "RMPTOUCR",
    baseURL: null,
    aliases: ["rmptouncertainty", "rmptouncertainties"],
  },
  SUP: {
    modelName: "Supplier",
    typeCode: "SUP",
    baseURL: "suppliers",
    aliases: ["supplier", "suppliers"],
  },
  LIBRARY_MATERIAL: {
    modelName: "Library Material",
    typeCode: "MTL",
    baseURL: "library/materials",
    aliases: ["librarymaterial", "librarymaterials"],
  },
  SPECIFICATION: {
    modelName: "Specification",
    typeCode: "MTLS",
    baseURL: null,
    aliases: ["specification", "specifications"],
  },
  TPP: {
    modelName: "TPP Section",
    typeCode: "TPP",
    baseURL: "product/tpp",
    isInProjectContext: true,
    aliases: ["tpp", "tpps", "tppsection", "tppsections", "target product profile", "target product profile element"],
  },
  TR: {
    modelName: "Training Record",
    typeCode: "TR",
    baseURL: "documents",
    aliases: ["trainingrecord", "trainingrecords"],
  },
  TT: {
    modelName: "Tech Transfer",
    typeCode: "TT",
    baseURL: null,
    aliases: ["tt", "tts", "techtransfer", "techtransfers"],
  },
  UO: {
    modelName: "Unit Operation",
    typeCode: "UO",
    baseURL: "processExplorer/unitOperations",
    isInProjectContext: true,
    aliases: ["unitoperation", "unitoperations", "uo", "uos"],
  },
  USR: {
    modelName: "User",
    typeCode: "USR",
    baseURL: "users",
    aliases: ["user", "users"],
  },
  STP: {
    modelName: "Step",
    typeCode: "STP",
    baseURL: "processExplorer/steps",
    isInProjectContext: true,
    aliases: ["step", "steps", "stp", "stps"],
  },
  DP: {
    modelName: "Drug Product",
    typeCode: "DP",
    baseURL: "product/qtpp/drugProducts",
    isInProjectContext: true,
    aliases: ["drugproduct", "drugproducts"],
  },
  DP_TO_PR: {
    modelName: "Drug Product To Process",
    typeCode: "DrugProductToProcess",
    baseURL: null,
    aliases: ["drugproducttoprocess", "drugproducttoprocesses"],
  },
  DP_TO_FQA: {
    modelName: "Drug Product To FQA",
    typeCode: "DPTOFQA",
    baseURL: null,
    aliases: ["drugproducttofqa", "drugproducttofqas"],
  },
  DS: {
    modelName: "Drug Substance",
    typeCode: "DS",
    baseURL: "product/qtpp/drugSubstances",
    isInProjectContext: true,
    aliases: ["drugsubstance", "drugsubstances"],
  },
  DS_TO_PR: {
    modelName: "Drug Substance To Process",
    typeCode: "DrugSubstanceToProcess",
    baseURL: null,
    aliases: ["drugsubstancetoprocess", "drugsubstancetoprocesses"],
  },
  DS_TO_FQA: {
    modelName: "Drug Substance To FQA",
    typeCode: "DSTOFQA",
    baseURL: null,
    aliases: ["drugsubstancetofqa", "drugsubstancetofqas"],
  },
  IQA_TO_FQA_LIBRARY: {
    modelName: "IQA To FQALibrary",
    typeCode: "IQATOFQALIBRARY",
    baseURL: null,
    aliases: ["iqatofqalibraries", "iqatofqalibrary"],
  },
};

module.exports = {
  MODEL_DECLARATIONS,
};
