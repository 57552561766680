"use strict";

import * as UIUtils from "../../ui_utils";
import BaseObjectCache, { inMemoryCache } from "./base_object_cache";
import { Log, LOG_GROUP } from "../../../server/common/logger/common_log";

const Logger = Log.group(LOG_GROUP.Framework, "Cache");

/**
 *  This is a map of type->id->onLoadedFunctions. It's also what we use to tell if a given type->id has already been
 *  loaded on this page.
 * @type {{objectOptionsCache: {}, typeaheadOptionsCache: {}}}
 */
let typeToIdToOnLoadedFunctions = {};
export const TYPES_TO_CACHE_ACROSS_PAGES = {User: true, Company: true, Project: true};
export const TYPES_TO_IGNORE_PROJECT_ID = {
  Curriculum: true,
  Document: true,
  ITP: true,
  Project: true,
  RMP: true,
  Supplier: true,
  User: true,
};
const TYPES_TO_IGNORE_PROCESS_ID = {
  ...TYPES_TO_IGNORE_PROJECT_ID,
  ControlMethod: true,
  FQA: true,
  FPA: true,
  GeneralAttribute: true,
  Process: true,
  TPPSection: true,
};
const TYPES_TO_INCLUDE_ARCHIVED = {Project: true};
/**
 * Do not use these cache names unless it's a static method where you can't use this.getCacheName() or this.getArchivedCacheName().
 *
 * @type {string}
 */
const CACHE_NAME = "typeaheadOptionsCache";
const ARCHIVED_CACHE_NAME = CACHE_NAME + "-archived";

/**
 * This class is responsible for both looking up and a keeping cache of typeahead options. This is a very short form
 * of the record that has just the id, name and perhaps a couple other pieces of information.
 *
 * Since the cache is stored in session storage, you don't need to use the same class that you loaded the typeahead to
 * see the typeahead values. Also, if 10 instances of this class all try to load the same type, the type will be loaded
 * only once.
 *
 * @see MetaModel.getAttributesForTypeahead() to see other attributes that are loaded.
 */
export default class TypeaheadObjectCache extends BaseObjectCache {
  /**
   * @param [type] {string} The type that will be loaded, unless this is the parent of the MultipleTypeaheadObjectCache,
   * then it will handle the types itself.
   * @param [projectId] {integer} Optionally the project ID that we want the typeaheads for.
   * @param [processId] {integer} Optionally the process ID that we want the typeheads for. `projectId` must be provided
   * if this is provided.
   * @param options
   */
  constructor(type, projectId, processId, options) {
    super(type);
    this.projectId = projectId ? UIUtils.parseInt(projectId) : -1;
    this.processId = processId ? UIUtils.parseInt(processId) : processId;

    // Initialize the structures that organize saving functions to be called when data is loaded.
    if (this.type && !typeToIdToOnLoadedFunctions[this.type]) {
      typeToIdToOnLoadedFunctions[this.type] = {};
    }

    // Create an ID for the parameters passed in.
    if (this.type) {
      this.id = -1;
      if (this.processId && !TypeaheadObjectCache.canTypeIgnoreProcessId(this.type, options)) {
        this.id = this.projectId + "--" + this.processId;
      } else if (!TypeaheadObjectCache.canTypeIgnoreProjectId(this.type, options)) {
        this.id = this.projectId;
      }
    }

    if (options?.invalidate) {
      this.invalidateCacheOptions();
    }
  }

  /**
   * @inheritDoc
   */
  getCacheName() {
    return CACHE_NAME;
  }

  /**
   * This cache stores archived records separately.
   *
   * @return {string} The name of the cache for archived records.
   */
  getArchivedCacheName() {
    return ARCHIVED_CACHE_NAME;
  }

  /**
   * @inheritDoc
   */
  getId() {
    return this.id;
  }

  /**
   * @inheritDoc
   */
  getIdToOnLoadedFunctions(type = this.type) {
    // noinspection JSValidateTypes
    return typeToIdToOnLoadedFunctions[type];
  }

  /**
   * @inheritDoc
   */
  buildURLForAjaxGet() {
    let url;
    if (this.type === "User") {
      url = "users/findUsersForTypeahead";
    } else {
      url = "editables/" + this.type + "/findForTypeahead/" + this.projectId;
    }
    let useWriterDB = UIUtils.getParameterByName("useWriterDB");
    if (useWriterDB) {
      url += "?useWriterDB=true";
    }
    return url;
  }

  addAjaxParameters(ajaxRequestData) {
    if (this.type === "ProcessComponent" ||
      this.type === "Material" ||
      this.type === "IQA" ||
      this.type === "IPA" ||
      this.type === "FQA" ||
      this.type === "FPA" ||
      this.type === "MaterialAttribute" ||
      this.type === "ProcessParameter" ||
      this.type === "DrugSubstance" ||
      this.type === "DrugProduct"
    ) {
      ajaxRequestData.includeLinks = true;
    }

    if (this.processId) {
      ajaxRequestData.processId = this.processId;
    }
  }

  static canTypeIgnoreProjectId(type, options) {
    if (type === "Document" && !options?.allProjects) {
      return false;
    }

    return TYPES_TO_IGNORE_PROJECT_ID[type] || (type === "Process" && options?.allProcesses);
  }

  static canTypeIgnoreProcessId(type) {
    return TYPES_TO_IGNORE_PROCESS_ID[type];
  }

  /**
   * Get the options from the cache.
   *
   * @return {[object]} An array of options for a typeahead or a single object for a particular ID.  If we're still
   * waiting on the data from the back end, the options returned will include a single record with a message to wait.
   */
  getOptionsFromCacheIncludingArchived() {
    let returnValue = this.getOptionsFromCache();
    let archived = this.getOptionsFromInMemory(this.getId(), this.getArchivedCacheName(), this.type);
    if (archived && archived.length > 0) {
      returnValue = returnValue.concat(archived);
    }
    return returnValue;
  }

  setCacheOptions(options, type = this.type, id = this.getId(), onlyInMemory = false) {
    Logger.debug(() => "TypeAheadObjectCache :: setCacheOptions for " + type + (onlyInMemory ? " only in memory" : ""), Log.object(options));

    if (TYPES_TO_INCLUDE_ARCHIVED[type]) {
      this.setCacheOptionsIntoMemory(this.getCacheName(), options, type, id);
      if (!onlyInMemory) {
        return BaseObjectCache.persistInMemoryCacheToStorage(this.getCacheName());
      }
    } else {
      // Filter out the different options into separate arrays
      let nonArchivedOptions = [];
      let archivedOptions = [];
      for (const option of options) {
        if (option.deletedAt) {
          archivedOptions.push(option);
        } else {
          nonArchivedOptions.push(option);
        }
      }

      this.setCacheOptionsIntoMemory(this.getCacheName(), nonArchivedOptions, type, id);
      this.setCacheOptionsIntoMemory(this.getArchivedCacheName(), archivedOptions, type, id);

      if (!onlyInMemory) {
        BaseObjectCache.persistInMemoryCacheToStorage(this.getCacheName()).then(() => {
          return BaseObjectCache.persistInMemoryCacheToStorage(this.getArchivedCacheName());
        });
      }
    }
  }

  /**
   * Call this method to invalidate cache options, so they're reloaded for the type passed into the constructor the next
   * time one of the load() * methods are called.
   */
  invalidateCacheOptions() {
    Logger.debug("TypeaheadObjectCache :: invalidateCacheOptions");
    super.invalidateCacheOptions();

    // invalidate archived elements
    return this.invalidateCacheHelper(this.getArchivedCacheName());
  }

  clearIdToOnLoadedFunctions(type = this.type) {
    typeToIdToOnLoadedFunctions[type] = {};
  }

  /**
   * Invalidates all options for all types unless they are in the TYPES_TO_CACHE_ACROSS_PAGES constant.
   */
  static invalidateOptionsOnNewPageLoad() {
    Logger.debug("TypeaheadObjectCache :: invalidateOptionsOnNewPageLoad");
    return TypeaheadObjectCache.invalidateOptionsOnNewPageLoadAsync();
  }

  static invalidateOptionsOnNewPageLoadAsync() {
    Logger.debug("TypeaheadObjectCache :: invalidateOptionsOnNewPageLoadAsync");
    TypeaheadObjectCache.invalidateOptionsFromInMemory();
    return Promise.all([TypeaheadObjectCache.persistInMemoryCacheToStorage(CACHE_NAME),
      TypeaheadObjectCache.persistInMemoryCacheToStorage(ARCHIVED_CACHE_NAME)]);
  }

  static invalidateOptionsFromInMemory() {
    const typeToIdToValues = inMemoryCache[CACHE_NAME];
    if (typeToIdToValues) {
      const archiveTypeToIdToValues = inMemoryCache[ARCHIVED_CACHE_NAME];
      for (const type of Object.keys(typeToIdToValues)) {
        if (!TYPES_TO_CACHE_ACROSS_PAGES[type]) {
          typeToIdToValues[type] = {};
          archiveTypeToIdToValues[type] = {};
        }
      }
    } else {
      // Initialize the cache as empty, since it doesn't exist.
      inMemoryCache[CACHE_NAME] = {};
      inMemoryCache[ARCHIVED_CACHE_NAME] = {};
    }
  }
}
